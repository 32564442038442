<template>
  <div>
    <v-row align="center" class="mx-0 pa-3 text-center flex-column">
      <help :helpId="38" class="help--crud" />
      <h2 class="mx-5">Áreas funcionales</h2>
      <span class="text-body-2"> {{ company.name }}</span>
    </v-row>
    <v-row no-gutters justify="end" class="px-3">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            color="primary"
            dark
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </template>
        <span>Crear área funcional</span>
      </v-tooltip>
    </v-row>

    <v-row class="mx-0">
      <label class="col-12 py-0">Filtrar por:</label>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-text-field
          dense
          solo
          hide-details="auto"
          label="Búsqueda"
          v-model="filters.search"
        />
      </v-col>
      <v-col cols="12" sm class="d-flex align-end justify-end">
        <v-btn
          small
          color="primary"
          max-width="35"
          min-width="auto"
          dark
          class="px-1 py-1"
          v-if="filterActivated"
          @click.prevent="
            filterActivated = false
            cleanFilters()
            getFunctionalAreas()
          "
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="primary"
              dark
              max-width="35"
              min-width="auto"
              class="px-1 py-1 ml-1"
              @click.prevent="
                filterActivated = true
                getFunctionalAreas()
              "
              ><v-icon dark> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <simple-list
      :title="''"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :itemKey="'id'"
      :hideFooter="true"
      :hideHeader="false"
      :multiSort="false"
      :loading="loading"
      :class="'my-2 pb-2'"
      :sortable="false"
      :page="page"
      :pages="pages"
      :extraButtoms="false"
      :showCreate="false"
      :showDelete="true"
      :buttonLink="'empresas/ficha'"
      :orderOptions="orderOptions"
      :orderBy="orderBy"
      @create:item="handleCreate()"
      @edit:item="handleEdit($event)"
      @delete:item="deleteItem($event)"
      @update:page="pagination($event)"
      @orderEvent="handleOrder($event)"
    />
    <modal name="functionalAreasModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Área Funcional</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Área Funcional</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Nombre"
                v-model="name"
              />
            </v-col>
            <v-col cols="12" lg="8" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Descripción"
                v-model="description"
              />
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('functionalAreasModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>
<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'

  export default {
    name: 'functional-areas',
    components: {
      SimpleList,
      Help,
    },
    props: {
      company: { type: Object },

      typeId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        config: {},
        loading: true,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre', value: 'name' },
          { text: 'Descripción', value: 'description' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          periodicity: null,
        },
        itemsPeriodicity: [
          { id: 0, name: 'Diario' },
          { id: 1, name: 'Semanal' },
          { id: 2, name: 'Quincenal' },
          { id: 3, name: 'Mensual' },
          { id: 4, name: 'Trimestral' },
          { id: 5, name: 'Semestral' },
          { id: 6, name: 'Anual' },
        ],
        description: '',
        name: '',
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {
      typeId(newValue) {
        this.getFunctionalAreas()
      },
    },
    computed: {},
    created() {
      this.getFunctionalAreas()
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      async getFunctionalAreas() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get(
            'functional-areas?type_id=' +
              this.typeId +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }

            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.description = ''

        this.$modal.show('functionalAreasModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getFunctionalAreas()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.description = this.item.description
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('functionalAreasModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.$axios
          .post('functional-areas', {
            type_id: this.typeId,
            name: this.name,
            description: this.description,
          })
          .then(() => {
            this.page = 1
            this.getFunctionalAreas()
            this.$modal.hide('functionalAreasModal')
          })
      },

      async saveItem() {
        this.$axios
          .patch('functional-areas/' + this.item.id, {
            type_id: this.typeId,
            name: this.name,
            description: this.description,
          })
          .then(() => {
            this.page = 1
            this.getFunctionalAreas()
            this.$modal.hide('functionalAreasModal')
          })
      },

      async deleteItem(item) {
        this.$axios.delete('functional-areas/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getFunctionalAreas()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getFunctionalAreas()
      },
    },
  }
</script>
