<template>
  <div>
    <v-row align="center" class="mx-0 pa-3 text-center flex-column">
      <help :helpId="37" class="help--crud" />
      <h2 class="mx-5">Perfiles de cargos</h2>
      <!-- <span class="text-body-2"> {{ company.name }}</span> -->
    </v-row>
    <v-row no-gutters justify="end" class="px-3"
      ><v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            color="primary"
            dark
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </template>
        <span>Crear Cargo</span>
      </v-tooltip>
    </v-row>

    <v-row class="mx-0">
      <label class="col-12 py-0">Filtrar por:</label>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-text-field
          dense
          solo
          hide-details="auto"
          label="Búsqueda"
          v-model="filters.search"
        />
      </v-col>
      <v-col cols="12" sm class="d-flex align-end justify-end">
        <v-btn
          small
          color="primary"
          max-width="35"
          min-width="auto"
          dark
          class="px-1 py-1"
          v-if="filterActivated"
          @click.prevent="
            filterActivated = false
            cleanFilters()
            getPositions()
          "
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              max-width="35"
              min-width="auto"
              dark
              small
              class="px-1 py-1 ml-1"
              @click.prevent="
                filterActivated = true
                getPositions()
              "
              ><v-icon dark> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <simple-list
      :title="''"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :itemKey="'id'"
      :hideFooter="true"
      :hideHeader="false"
      :multiSort="false"
      :loading="loading"
      :class="'my-2 pb-2'"
      :sortable="false"
      :page="page"
      :pages="pages"
      :extraButtoms="extraButtoms"
      :showCreate="false"
      :showDelete="true"
      :buttonLink="'empresas/ficha'"
      :orderOptions="orderOptions"
      :orderBy="orderBy"
      @create:item="handleCreate()"
      @edit:item="handleEdit($event)"
      @delete:item="deleteItem($event)"
      @update:page="pagination($event)"
      @orderEvent="handleOrder($event)"
      @showPositionProfile="handleProfileRedirect($event)"
      @positionSkill="handlePositionSkillRedirect($event)"
    />
    <modal name="positionListModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="ma-md-4" flat>
        <h2 class="text-center mb-5" v-if="flow === 'create'">Crear Cargo</h2>
        <h2 class="text-center mb-5" v-if="flow === 'edit'">Editar Cargo</h2>

        <v-card-text>
          <span v-if="flow === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >

          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row class="mx-0">
              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
                style="padding-top: 0px; padding-bottom: 12px"
              >
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 40%"
                    >Área funcional</label
                  >
                  <multiselect
                    style="width: 60%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="functionalAreaId"
                    :options="functionalAreas"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
                style="padding-top: 0px; padding-bottom: 12px"
              >
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 40%"
                    >Cargo supervisor</label
                  >
                  <multiselect
                    style="width: 60%"
                    v-model="positionId"
                    label="name"
                    track-by="id"
                    placeholder="Buscar"
                    open-direction="bottom"
                    :options="positions"
                    :multiple="false"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :limit-text="limitText"
                    :max-height="600"
                    :show-no-results="false"
                    :hide-selected="true"
                    @load="getSelectorPositions"
                    @search-change="getSelectorPositions"
                  >
                    <template slot="tag" slot-scope="{ option, remove }"
                      ><span class="custom__tag"
                        ><span>{{ option.name }}</span
                        ><span class="custom__remove" @click="remove(option)"
                          >❌</span
                        ></span
                      ></template
                    >
                    <template slot="clear" slot-scope="">
                      <div
                        class="multiselect__clear"
                        v-if="positionId && positionId.length"
                      />
                    </template>
                    <span slot="noResult"
                      >No se encontraron elementos. Considere cambiar la consulta de
                      búsqueda.</span
                    >
                  </multiselect>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
                style="padding-top: 0px; padding-bottom: 12px"
              >
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 20%"
                    >Estrato</label
                  >
                  <multiselect
                    style="width: 80%"
                    track-by="id"
                    label="description"
                    placeholder="Seleccionar"
                    v-model="strataId"
                    :options="strata"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required, rules.max]"
                  label="Nombre"
                  v-model="name"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required, rules.max]"
                  label="Propósito"
                  v-model="purpose"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required, rules.max]"
                  label="Misión"
                  v-model="mission"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required]"
                  label="Salario"
                  v-model="salary"
                  step="0.01"
                  prefix="USD "
                  type="number"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required]"
                  label="Puestos autorizados"
                  v-model="vacancies"
                  step="1"
                  :min="
                    item.users && item.users.length > 0
                      ? item.users.length + parseInt(frozenVacancies)
                      : 1
                  "
                  type="number"
                  @input="calculateVacanciesAvailable()"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  counter
                  :rules="[rules.required]"
                  label="Puestos congelados"
                  v-model="frozenVacancies"
                  step="1"
                  min="0"
                  :max="handleAvailable + frozenVacancies"
                  type="number"
                  @change="calculateVacanciesAvailable()"
                />
              </v-col>
              <!-- <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required]"
                label="Estatus"
                v-model="status"
                step="1"
                type="number"
              />
            </v-col> -->
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-checkbox
                  :label="
                    businessAgent ? 'Es agente comercial' : 'No es agente comercial'
                  "
                  v-model="businessAgent"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-checkbox :label="status ? 'Activo' : 'Inactivo'" v-model="status" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('positionListModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
    <org-chart class="mt-4" :key="orgChartIndex" :typeId="typeId" />
  </div>
</template>
<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  import OrgChart from '@/components/HumanTalent/OrgChart'
  import Help from '@/components/App/Help'

  export default {
    name: 'positions',
    components: {
      SimpleList,
      OrgChart,
      Help,
    },
    props: {
      company: { type: Object },
      typeId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        orgChartIndex: 1,
        config: {},
        loading: true,
        isLoading: false,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Área Funcional', value: 'functional_area.name' },
          { text: 'Nombre', value: 'name' },
          { text: 'Puestos autorizados', value: 'vacancies', align: 'end' },
          { text: 'Puestos congelados', value: 'frozen_vacancies', align: 'end' },

          { text: 'Acciones', value: 'actions', align: 'center', width: '140px' },
        ],
        items: [],
        users: [],

        positions: [{ id: null, name: 'No depende de otro cargo' }],
        functionalAreas: [],
        functionalAreaId: '',
        selectedUsers: [],
        usersSearched: [{ id: 0, name: 'No posee empresa principal' }],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        showPassword: false,
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          active: null,
          country: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        active: false,
        businessAgent: false,
        salary: '',
        vacancies: 1,
        frozenVacancies: 0,
        maxUsers: 1,
        status: '',
        name: '',
        purpose: '',
        mission: '',
        strataId: '',
        positionId: '',
        strata: [],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        country: '',
        selectCountryData: [],
        selectCityData: [],
        loadingText: 'Cargando registros',
        datas: [],
        image: null,
        arrayOfData: [],
        valid: true,
        rules: {
          required: (v) => v !== null || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        extraOptions: [
          {
            text: 'Áreas Funcionales',
            tooltip: 'Áreas Funcionales',
            link: 'administrative/human-talent/FunctionalAreas',
            icon: 'mdi-book-cog',
          },
          {
            text: 'Estratos',
            tooltip: 'Estratos',
            link: 'administrative/human-talent/Strata',
            icon: 'mdi-layers-triple',
          },
          {
            text: 'Movimientos',
            tooltip: 'Movimientos de Cargos',
            link: 'administrative/human-talent/Movements',
            icon: 'mdi-account-switch',
          },
        ],
        extraButtoms: [
          {
            text: 'Asignar competencias',
            icon: 'mdi-text-box-check',
            event: { active: true, name: 'positionSkill' },
          },
          {
            text: 'Ver perfil',
            icon: 'mdi-account-network',
            event: { active: true, name: 'showPositionProfile' },
          },
        ],
      }
    },
    created() {
      this.getUsers()
      this.getStrata()
      if (this.typeId) {
        this.getPositions()
        this.getfunctionalAreas()
      }
    },
    watch: {
      typeId() {
        this.getPositions()
        this.getfunctionalAreas()
      },
      company() {
        this.getUsers()
        this.getStrata()
      },
    },
    computed: {
      handleAvailable() {
        if (this.vacancies) {
          let assignedCount = 0
          let available = 0
          if (this.item.users && this.item.users.length > 0) {
            assignedCount = this.item.users.length
          }
          available =
            parseInt(this.vacancies) -
            parseInt(this.frozenVacancies) -
            parseInt(assignedCount)
          return available
        } else {
          return 0
        }
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      handleProfileRedirect(position) {
        this.$router.push({
          name: 'administrative/human-talent/PositionProfile',
          params: { positionId: position.id },
        })
      },
      handlePositionSkillRedirect(position) {
        this.$router.push({
          name: 'administrative/human-talent/PositionSkills',
          params: { position: position, positionId: position.id },
          props: true,
        })
      },
      calculateVacanciesAvailable() {
        if (this.vacancies) {
          let assignedCount = 0
          if (this.item.users && this.item.users.length > 0) {
            assignedCount = this.item.users.length
          }
          this.maxUsers =
            parseInt(this.vacancies) -
            parseInt(this.frozenVacancies) -
            parseInt(assignedCount) +
            1
        } else {
          this.maxUsers = 0
        }
      },
      limitText(count) {
        return `and ${count} other users`
      },
      getSelectorPositions(search) {
        this.isLoading = true
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('positions?limit=100&type_id=' + this.typeId + searchStr)
          .then((response) => {
            this.positions = [{ id: null, name: 'No depende de otro cargo' }]
            this.positions = this.positions.concat(response.data)
            this.isLoading = false
          })
      },

      async getUsers() {
        this.$axios
          .get('users?limit=1000&company_id=' + this.company.id)
          .then((response) => {
            this.users = response.data
          })
      },
      clearAll() {
        this.selectedCompany = []
      },

      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },

      cleanFilters() {
        this.filters = {
          search: '',
          active: null,
          country: null,
        }
      },

      findPositions(search) {
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('positions?limit=100&type_id=' + this.company.type_id + searchStr)
          .then((response) => {
            this.positions = response.data
          })
      },

      async getPositions() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        let searchString = ''
        let countryString = ''
        let roleString = ''
        let activeString = ''
        const withString = '&with[]=functionalArea'
        const appendString = '&appends[]=users_by_company'
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = '&country_id=' + this.filters.countryId.id
          }
          if (this.filters.role && this.filters.role.name) {
            roleString = '&role=' + this.filters.role.name
          }
          if (this.filters.active && this.filters.active.id) {
            activeString = '&active=' + this.filters.active.id
          }
        }
        queryParams =
          searchString +
          countryString +
          roleString +
          activeString +
          withString +
          appendString
        this.$axios
          .get(
            'positions?type_id=' +
              this.typeId +
              '&company_id=' +
              this.company.id +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items && this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.getSelectorPositions()
            this.orgChartIndex++
            this.loading = false
          })
      },

      async getfunctionalAreas() {
        this.$axios
          .get('functional-areas?limit=500&type_id=' + this.typeId)
          .then((response) => {
            this.functionalAreas = this.functionalAreas.concat(response.data)
          })
      },

      async getStrata() {
        this.$axios
          .get('stratas?limit=500&company_id=' + this.company.id)
          .then((response) => {
            this.strata = this.strata.concat(response.data)
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.status = false
        this.purpose = ''
        this.mission = ''
        this.vacancies = 1
        this.frozenVacancies = 0
        this.active = ''
        this.salary = ''
        this.strataId = ''
        this.positionId = ''
        this.functionalAreaId = ''
        this.active = false
        this.businessAgent = false
        this.$modal.show('positionListModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getPositions()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.status = this.item.status === 1
        this.purpose = this.item.purpose
        this.mission = this.item.mission
        this.vacancies = this.item.vacancies ? this.item.vacancies : 1
        this.frozenVacancies = this.item.frozen_vacancies ? this.item.frozen_vacancies : 0
        this.active = this.item.active
        this.salary = this.item.salary

        this.strataId = this.strata.find((strata) => strata.id === this.item.strata_id)
        this.positionId = this.positions.find(
          (position) => position.id === this.item.position_id
        )
        this.functionalAreaId = this.functionalAreas.find(
          (area) => area.id === this.item.functional_area_id
        )
        this.active = this.item.active
        this.businessAgent = this.item.business_agent
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.calculateVacanciesAvailable()

        this.$modal.show('positionListModal')
      },
      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.status = this.status ? 1 : 0
        this.businessAgent = this.businessAgent ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }

        const formData = new FormData()
        formData.append('type_id', this.typeId)
        if (this.positionId && this.positionId.id) {
          formData.append('position_id', this.positionId.id)
        }
        if (this.strataId && this.strataId.id) {
          formData.append('strata_id', this.strataId.id)
        }
        if (this.functionalAreaId && this.functionalAreaId.id) {
          formData.append('functional_area_id', this.functionalAreaId.id)
        }
        formData.append('name', this.name)
        formData.append('purpose', this.purpose)
        formData.append('status', this.status)
        formData.append('business_agent', this.businessAgent)
        formData.append('active', this.active)
        formData.append('salary', this.salary)
        formData.append('mission', this.mission)
        formData.append('vacancies', this.vacancies)
        formData.append('frozen_vacancies', this.frozenVacancies)
        this.$axios.post('positions', formData, this.config).then(() => {
          this.page = 1
          this.getPositions()
          this.$modal.hide('positionListModal')
        })
      },

      async saveItem() {
        this.status = this.status ? 1 : 0
        this.businessAgent = this.businessAgent ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }

        const formData = new FormData()
        formData.append('type_id', this.typeId)
        if (this.positionId && this.positionId.id) {
          formData.append('position_id', this.positionId.id)
        }
        if (this.strataId && this.strataId.id) {
          formData.append('strata_id', this.strataId.id)
        }
        if (this.functionalAreaId && this.functionalAreaId.id) {
          formData.append('functional_area_id', this.functionalAreaId.id)
        }
        formData.append('name', this.name)
        formData.append('purpose', this.purpose)
        formData.append('salary', this.salary)
        formData.append('status', this.status)
        formData.append('business_agent', this.businessAgent)
        formData.append('active', this.active)
        formData.append('mission', this.mission)
        formData.append('vacancies', this.vacancies)
        formData.append('frozen_vacancies', this.frozenVacancies)
        formData.append('_method', 'patch')
        this.$axios.post('positions/' + this.item.id, formData, this.config).then(() => {
          this.page = 1
          this.getPositions()
          this.$modal.hide('positionListModal')
        })
      },

      async deleteItem(item) {
        this.$axios.delete('positions/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getPositions()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getPositions()
      },
    },
  }
</script>
