<template>
  <div>
    <v-row align="center" class="mx-0 pa-3 text-center flex-column">
      <!-- <help :helpId="40" class="help--crud" /> -->
      <h2 class="mx-5">Asignación de Personal</h2>
    </v-row>
    <v-row class="mx-0">
      <label class="col-12 py-0">Filtrar por:</label>
      <v-col cols="12" lg="3" md="4" sm="6">
        <span class="caption">Búsqueda simple</span>
        <v-text-field
          dense
          solo
          hide-details="auto"
          label="Búsqueda"
          v-model="filters.search"
        />
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <span class="caption">Empresa</span>
        <multiselect
          track-by="id"
          label="name"
          deselect-label="Remover"
          select-label="Seleccionar"
          selected-label="Seleccionado"
          placeholder="Seleccione"
          :allow-empty="false"
          v-model="selectedCompany"
          :options="companies"
          :loading="loadingCompanies"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="false"
          @search-change="getCompanies"
          @select="getPositions"
        />
      </v-col>
      <v-col cols="12" sm class="d-flex align-end justify-end">
        <v-btn
          small
          color="primary"
          max-width="35"
          min-width="auto"
          dark
          class="px-1 py-1"
          v-if="filterActivated"
          @click.prevent="
            filterActivated = false
            cleanFilters()
            getPositions()
          "
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="primary"
              dark
              max-width="35"
              min-width="auto"
              class="px-1 py-1 ml-1"
              @click.prevent="
                filterActivated = true
                getPositions()
              "
              ><v-icon dark> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <simple-list
      :title="''"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :itemKey="'id'"
      :hideFooter="true"
      :hideHeader="false"
      :multiSort="false"
      :loading="loadingPositions"
      :class="'my-2 pb-2'"
      :sortable="false"
      :page="page"
      :pages="pages"
      :extraButtoms="extraButtoms"
      :showCreate="false"
      :showEdit="false"
      :showDelete="false"
      :showGroupBy="false"
      :buttonLink="'empresas/ficha'"
      :orderOptions="orderOptions"
      :orderBy="orderBy"
      @edit:item="handleEdit($event)"
      @update:page="pagination($event)"
      @orderEvent="handleOrder($event)"
    />
    <modal name="employesModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-0" elevation="0">
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-toolbar color="primary" light height="40">
            <v-toolbar-title class="white--text">
              Asignación de Personal
            </v-toolbar-title>
          </v-toolbar>
          <v-list two-line subheader>
            <v-card elevation="0" v-for="(item, index) in userBackData" :key="index">
              <v-row class="mx-0 mt-4">
                <v-col cols="10" sm="6">
                  <multiselect
                    track-by="id"
                    :custom-label="fullName"
                    placeholder="Seleccionar usuario"
                    v-model="item.user"
                    :options="users"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    :show-labels="false"
                    :allow-empty="false"
                    :disabled="item.user ? true : false"
                    value="id"
                    @search-change="getUsers"
                  />
                </v-col>
                <v-col class="" cols="10" sm="5">
                  <v-text-field
                    hide-details="auto"
                    v-model="item.date"
                    label="Fecha de contratación"
                    type="date"
                  />
                </v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      title="Eliminar Archivo"
                      icon
                      rounded
                      class="align-self-center mt-n12 mt-sm-0 mx-auto col-2 col-sm-1"
                      @click="handleRemoveFile(index)"
                      ><v-icon color="red">mdi-close</v-icon></v-btn
                    >
                  </template>
                  <span>Eliminar usuario</span>
                </v-tooltip>
              </v-row>
            </v-card>
            <v-row class="mx-0" justify="end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text @click="handleNewFile()"
                    >Añadir</v-btn
                  >
                </template>
                <span>Añadir usuario</span>
              </v-tooltip>
            </v-row>
          </v-list>
        </v-form>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('employesModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>
<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  // import Help from '@/components/App/Help'

  export default {
    name: 'Employes',
    components: {
      SimpleList,
      // Help,
    },
    props: {
      company: {
        type: Object,
        required: true,
      },
      typeId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        config: {},
        loadingPositions: false,
        loadingUsers: false,
        loadingCompanies: false,
        companies: [],
        selectedCompany: {},
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Cargo', value: 'name' },
          { text: 'Empleados', value: 'employesCount', align: 'end' },
          { text: 'Puestos disponibles', value: 'availables', align: 'end' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        users: [],
        positions: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,

        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        userBackData: [{ user: null, date: '' }],
        filters: {
          search: '',
        },

        loadingText: 'Cargando registros',
        valid: true,

        rules: {
          required: (v) => !!v || 'Requerido.',
        },
        extraButtoms: [
          {
            text: 'Asignar Personal',
            icon: 'mdi-account-convert',
            event: { active: true, name: 'edit:item' },
          },
        ],
      }
    },
    watch: {
      userBackData(newValue) {
        newValue.forEach((element) => {
          if (element.user && element.user.id) {
            this.users = this.users.filter((user) => user.id !== element.user.id)
          }
        })
      },
    },
    created() {
      this.getCompanies()
      this.getPositions()
    },
    computed: {},
    methods: {
      toLocaleDateString: toLocaleDateString,
      handleNewFile() {
        this.userBackData.push({ user: null, date: '' })
      },
      handleRemoveFile(index) {
        if (this.userBackData.length === 1) {
          this.userBackData = [{ user: null, date: '' }]
        } else if (this.userBackData.length > 1) {
          this.userBackData.splice(index, 1)
        }
      },

      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      async getPositions() {
        this.loadingPositions = true
        let queryParams = ''
        let orderString = ''
        let searchString = ''
        const withString = ''
        const appendString = '&appends[]=users_by_company'
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
        }
        queryParams = searchString + withString + appendString
        this.$axios
          .get(
            'positions?type_id=' +
              this.typeId +
              '&company_id=' +
              this.company.id +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items && this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((element) => {
              element.employesCount = element.users_by_company.length
            })
            this.loadingPositions = false
          })
      },
      async getCompanies(search) {
        this.loadingCompanies = true
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('companies?order=name&by=asc&limit=100&type_id=' + this.typeId + searchStr)
          .then((response) => {
            this.companies = response.data
            if (this.typeId === this.company.type_id) {
              this.selectedCompany = this.company
            } else {
              this.selectedCompany = response.data.length > 0 ? response.data[0] : {}
            }
            if (this.selectedCompany.id) {
              this.getUsers()
            }
            this.loadingCompanies = false
          })
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getPositions()
      },
      async handleEdit(item) {
        this.item = item
        this.userBackData = [{ user: null, date: '' }]
        if (item.users_by_company && item.users_by_company.length > 0) {
          this.item.users_by_company.forEach((user) =>
            this.userBackData.unshift({ user: user, date: user.position_assigned_at })
          )
        }
        this.$modal.show('employesModal')
      },
      validate() {
        this.$refs.formCompany.validate()
      },
      reset() {
        this.userBackData = [{ user: null, date: '' }]
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },
      getUsers(query) {
        this.loadingUsers = true
        let searchString = ''

        if (query && query !== '') {
          searchString = '&search=' + query
        }
        this.$axios
          .get('users?limit=100&company_id=' + this.selectedCompany.id + searchString)
          .then((response) => {
            this.users = response.data
            this.loadingUsers = false
          })
      },
      async saveItem() {
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        formData.append('_method', 'patch')

        if (this.selectedCompany && this.selectedCompany.id) {
          formData.append('company_id', this.selectedCompany.id)
        }

        this.userBackData.forEach((item, index) => {
          if (item.user && item.user.id && item.date !== '') {
            formData.append('users[' + index + '][user_id]', item.user.id)
            formData.append('users[' + index + '][date]', item.date)
          }
        })
        this.$axios.post('positions/' + this.item.id, formData, this.config).then(() => {
          this.page = 1
          this.getPositions()
          this.$modal.hide('employesModal')
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getPositions()
      },
    },
  }
</script>
