<template>
  <div class="vuse-content-wrapper positions">
    <v-container fluid>
      <smart-breadcrumbs :items="navigationElements" />
      <!-- Content -->
      <v-row align="center" class="my-2" v-if="showTypeSelector" no-gutters
        ><span class="caption mr-1">Formato</span
        ><multiselect
          track-by="id"
          label="name"
          placeholder="Seleccionar formato"
          deselect-label="Remover"
          select-label="Seleccionar"
          selected-label="Seleccionado"
          v-model="selectedType"
          :allow-empty="false"
          :options="types"
          :loading="loadingTypes"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="false"
          class=""
          style="max-width: 300px"
          @select="updateSelectedType($event)"
      /></v-row>
      <v-card>
        <v-toolbar color="primary" dark flat dense>
          <template v-slot:default>
            <v-tabs v-model="model" centered slider-color="teal">
              <v-tab href="#1"> Cargos </v-tab>
              <v-tab href="#2"> Personal </v-tab>
              <v-tab href="#5"> Competencias </v-tab>
              <v-tab href="#3"> Movimientos </v-tab>
              <v-tab href="#4"> Áreas </v-tab>
              <v-tab href="#6"> Estratos </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <keep-alive>
          <v-tabs-items v-model="model">
            <v-tab-item value="1">
              <positions-list
                v-if="typeId > 0"
                :key="typeIndex"
                :company="company"
                :typeId="typeId"
              />
            </v-tab-item>
            <v-tab-item value="2">
              <employes
                v-if="typeId > 0"
                :key="typeIndex"
                :company="company"
                :typeId="typeId"
              />
            </v-tab-item>
            <v-tab-item value="3">
              <movements
                :company="company"
                v-if="typeId > 0"
                :key="typeIndex"
                :typeId="typeId"
              />
            </v-tab-item>
            <v-tab-item value="4">
              <functional-areas
                v-if="typeId > 0"
                :key="typeIndex"
                :company="company"
                :typeId="typeId"
              />
            </v-tab-item>
            <v-tab-item value="5">
              <skills
                v-if="typeId > 0"
                :key="typeIndex"
                :company="company"
                :typeId="typeId"
              />
            </v-tab-item>
            <v-tab-item value="6">
              <strata v-if="typeId > 0" :company="company" />
            </v-tab-item>
          </v-tabs-items>
        </keep-alive>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import PositionsList from '@/components/HumanTalent/PositionsList'
  import Strata from '@/components/HumanTalent/Strata'
  import Skills from '@/components/HumanTalent/Skills'
  import FunctionalAreas from '@/components/HumanTalent/FunctionalAreas'
  import Movements from '@/components/HumanTalent/Movements'
  import Employes from '@/components/HumanTalent/Employes'
  export default {
    name: 'positions',
    components: {
      Strata,
      Skills,
      Movements,
      FunctionalAreas,
      Employes,
      PositionsList,
    },
    data() {
      return {
        typeIndex: 0,
        typeId: 0,
        types: [],
        selectedType: null,
        loadingTypes: false,
        model: this.$route.query.tap ? parseInt(this.$route.query.tap) : '1',
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Perfiles de Cargos',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.getTypes()
      this.handleTab()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {
          this.typeId = newValue.type_id
          this.typeIndex++
        },
      },
      companies() {
        if (
          this.$session.get('companies') &&
          decryptData(this.$session.get('companies'))
        ) {
          return decryptData(this.$session.get('companies'))
        } else {
          return []
        }
      },
      showTypeSelector() {
        if (this.companies.length > 0) {
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      handleTab() {
        const route = window.location.href.split('tab=')
        if (route.length > 1) {
          this.model = route[1]
        }
      },
      getTypes() {
        this.loadingTypes = true
        this.$axios.get('types?limit=20').then((response) => {
          this.types = this.types.concat(response.data)
          this.selectedType = response.data.find((x) => x.id === this.company.type_id)
          this.typeId = this.selectedType.id
          this.loadingTypes = false
        })
      },
      updateSelectedType(event) {
        this.typeId = event.id
        this.typeIndex++
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .positions {
    .v-breadcrumbs {
      padding: 5px 10px !important;
    }
    .v-tabs-slider {
      margin-top: -5px;
    }
  }
</style>
