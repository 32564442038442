<template>
  <div>
    <v-row align="center" class="mx-0 pa-3 text-center flex-column">
      <help :helpId="40" class="help--crud" />
      <h2 class="mx-5">Movimientos de Cargos</h2>
    </v-row>

    <v-row no-gutters justify="end" class="px-3">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            color="#adadb0"
            class="mr-1"
            dark
            v-bind="attrs"
            v-on="on"
            @click="$router.push({ name: 'administrative/human-talent/MovementReasons' })"
            >Motivos
          </v-btn>
        </template>
        <span>Motivos de movimientos</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            color="primary"
            dark
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </template>
        <span>Crear movimiento</span>
      </v-tooltip>
    </v-row>

    <v-row class="mx-0">
      <label class="col-12 py-0">Filtrar por:</label>
      <v-col cols="12" lg="3" md="4" sm="6">
        <span class="caption">Búsqueda simple</span>
        <v-text-field
          dense
          solo
          hide-details="auto"
          label="Escriba aquí"
          v-model="filters.search"
        />
      </v-col>
      <v-col cols="10" lg="3" md="4" sm="6">
        <span class="caption">Empresa</span>
        <multiselect
          track-by="id"
          label="name"
          deselect-label="Remover"
          select-label="Seleccionar"
          selected-label="Seleccionado"
          placeholder="Seleccione"
          :allow-empty="false"
          v-model="selectedCompany"
          :options="companies"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="false"
          @search-change="getCompanies"
          @select="getMovements"
        />
      </v-col>
    </v-row>
    <v-col cols="12" sm class="d-flex align-end justify-end">
      <v-btn
        small
        color="primary"
        max-width="35"
        min-width="auto"
        dark
        class="px-1 py-1"
        v-if="filterActivated"
        @click.prevent="
          filterActivated = false
          cleanFilters()
          getMovements()
        "
      >
        <v-icon dark> mdi-delete </v-icon>
      </v-btn>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            color="primary"
            dark
            max-width="35"
            min-width="auto"
            class="px-1 py-1 ml-1"
            @click.prevent="
              filterActivated = true
              getMovements()
            "
            ><v-icon dark> mdi-filter </v-icon>
          </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>
    </v-col>

    <simple-list
      :title="''"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :itemKey="'id'"
      :hideFooter="true"
      :hideHeader="false"
      :multiSort="false"
      :loading="loadingMovements"
      :class="'my-2 pb-2'"
      :sortable="false"
      :page="page"
      :pages="pages"
      :extraButtoms="false"
      :showCreate="false"
      :showDelete="true"
      :showGroupBy="false"
      :buttonLink="'empresas/ficha'"
      :orderOptions="orderOptions"
      :orderBy="orderBy"
      @create:item="handleCreate()"
      @edit:item="handleEdit($event)"
      @delete:item="deleteItem($event)"
      @update:page="pagination($event)"
      @orderEvent="handleOrder($event)"
    />
    <modal name="movementsModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">
          Crear Movimiento de Cargo
        </h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">
          Editar Movimiento de Cargo
        </h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%">Tipo</label>
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="name"
                  :disabled="flow === 'edit' ? true : false"
                  placeholder="Seleccionar"
                  v-model="type"
                  :options="movementTypes"
                  @input="getMovementReasons()"
                  :multiple="false"
                  :allow-empty="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-row style="margin: auto 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%"
                  >Usuario</label
                >
                <multiselect
                  style="width: 60%"
                  v-model="selectedUser"
                  label="name"
                  :custom-label="fullName"
                  track-by="id"
                  :disabled="flow === 'edit' ? true : false"
                  placeholder="Buscar"
                  open-direction="bottom"
                  :options="usersSearched"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :allow-empty="false"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="asyncFind"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="custom__tag"
                      ><span>{{ option.name }}</span
                      ><span class="custom__remove" @click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >
                  <template slot="clear" slot-scope="">
                    <div
                      class="multiselect__clear"
                      v-if="selectedUser && selectedUser.length"
                    /> </template
                  ><span slot="noResult"
                    >No se encontraron elementos. Considere cambiar la consulta de
                    búsqueda.</span
                  >
                </multiselect>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%">Cargo</label>
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="name"
                  :disabled="flow === 'edit' ? true : false"
                  placeholder="Seleccionar"
                  v-model="position"
                  :options="positions"
                  :multiple="false"
                  :allow-empty="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%"
                  >Motivo</label
                >
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="description"
                  :disabled="flow === 'edit' ? true : false"
                  placeholder="Seleccionar"
                  v-model="movementReason"
                  :options="movementReasons"
                  :multiple="false"
                  :allow-empty="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Observación"
                v-model="observation"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-text-field
                show-current
                :rules="[rules.required, rules.max]"
                label="Fecha efectiva"
                v-model="date"
                type="date"
              />
            </v-col>
            <!-- <v-col cols="12" lg="4" md="6">
              <div
                class="row"
                style="display: flex; justify-content: center; align-items: center"
              >
                <img
                  v-if="flow === 'edit' && item.uploads && item.uploads.length > 0"
                  :src="item.item.uploads[0].route"
                  :alt="item.name"
                  style="width: 40%"
                />
                <v-file-input
                  v-model="image"
                  clearable
                  chips
                  :rules="[rules.fileSize]"
                  ref="file"
                  show-size
                  accept="image/png, image/jpeg, image/jpg"
                  :label="'Seleccione una foto'"
                  style="width: 60%"
                />
              </div>
            </v-col> -->
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('movementsModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>
<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'

  export default {
    name: 'movements',
    components: {
      SimpleList,
      Help,
    },
    props: {
      typeId: {
        type: Number,
        required: true,
      },
      company: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        config: {},
        loadingMovements: false,
        isLoading: false,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        companies: [],
        selectedCompany: {},
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Cargo', value: 'position.name' },
          { text: 'Usuario', value: 'userFullName' },
          { text: 'Tipo', value: 'typeText' },
          { text: 'Fecha', value: 'dateText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        usersSearched: [],
        positions: [],
        movementReasons: [],
        movementReason: null,
        position: null,
        selectedUser: null,
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          periodicity: null,
        },
        movementTypes: [
          { id: 1, name: 'Ingreso' },
          { id: 2, name: 'Transferencia' },
          { id: 3, name: 'Ascenso' },
          { id: 4, name: 'Egreso' },
        ],
        observation: '',
        type: { id: 1, name: 'Ingreso' },
        date: new Date().toISOString().split('T')[0],
        image: null,
        loadingText: 'Cargando registros',
        valid: true,
        companyFilterId: null,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        extraOptions: [
          {
            text: 'Motivos',
            tooltip: 'Motivos de Movimientos',
            link: 'administrative/human-talent/Metrics',
            icon: 'mdi-chart-bar-stacked',
          },
        ],
      }
    },
    watch: {},
    created() {
      this.getCompanies()
      this.getPositions()
      this.getMovementReasons()
      this.asyncFind()
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      limitText(count) {
        return `and ${count} other companies`
      },
      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      cleanFilters() {
        this.filters = {
          search: '',
          active: null,
          country: null,
        }
      },

      async getCompanies(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('companies?order=name&by=asc&limit=500&type_id=' + this.typeId + searchStr)
          .then((response) => {
            this.companies = response.data
            if (this.typeId === this.company.type_id) {
              this.selectedCompany = this.company
            } else {
              this.selectedCompany = response.data[0]
            }
            this.getMovements()
          })
      },
      async getPositions() {
        this.$axios
          .get('positions?limit=1000&type_id=' + this.company.type_id)
          .then((response) => {
            this.positions = response.data
          })
      },

      async getMovementReasons() {
        if (this.type && this.type.id) {
          this.$axios
            .get('movement-reasons?limit=1000&type=' + this.type.id)
            .then((response) => {
              this.movementReasons = response.data
            })
        }
      },

      async getMovements() {
        this.loadingMovements = true
        let queryParams = ''
        let orderString = ''
        let searchString = ''
        const withString = '&with[]=user&with[]=movementReason&with[]=position'
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
        }
        queryParams = searchString + withString
        this.$axios
          .get(
            'movements?page=' +
              this.page +
              '&company_id=' +
              this.selectedCompany.id +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((movement) => {
              switch (movement.type) {
                case 1:
                  movement.typeText = 'Ingreso'
                  break
                case 2:
                  movement.typeText = 'Transferencia'
                  break
                case 3:
                  movement.typeText = 'Ascenso'
                  break
                case 4:
                  movement.typeText = 'Egreso'
                  break
                default:
                  movement.typeText = ''
                  break
              }
              movement.dateText = new Date(movement.date).toISOString().split('T')[0]
              movement.userFullName = `${movement.user.name} ${movement.user.lastname} | ${movement.user.email}`
            })
            this.loadingMovements = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.date = new Date().toISOString().split('T')[0]
        this.type = { id: 1, name: 'Ingreso' }
        this.observation = ''
        this.image = null
        this.position = {}
        this.selectedUser = {}
        this.movementReason = {}

        this.$modal.show('movementsModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getMovements()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.observation = this.item.observation
        this.date = new Date(this.item.date).toISOString().split('T')[0]
        this.type = this.item.type
          ? this.movementTypes.find((type) => type.id === this.item.type)
          : ''
        this.image = null
        this.position = this.positions.find(
          (position) => position.id === this.item.position_id
        )
        this.selectedUser = this.usersSearched.find(
          (user) => user.id === this.item.user_id
        )
        this.movementReason = this.movementReasons.find(
          (movement) => movement.id === this.item.movement_reason_id
        )
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.getMovementReasons()
        this.$modal.show('movementsModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      asyncFind(query) {
        this.isLoading = true
        let searchString = ''
        this.usersSearched = [{ id: 0, name: 'No posee empresa principal' }]
        if (query && query !== '') {
          searchString = '&search=' + query
        }
        this.$axios
          .get('users?company_id=' + this.company.id + searchString)
          .then((response) => {
            this.usersSearched = [{ id: 0, name: 'No posee empresa principal' }]
            this.usersSearched = this.usersSearched.concat(response.data.data)
            this.isLoading = false
          })
      },

      async createItem() {
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        formData.append('observation', this.observation)
        if (this.selectedUser && this.selectedUser.id) {
          formData.append('user_id', this.selectedUser.id)
        }
        if (this.position && this.position.id) {
          formData.append('position_id', this.position.id)
        }
        if (this.movementReason && this.movementReason.id) {
          formData.append('movement_reason_id', this.movementReason.id)
        }
        if (this.type && this.type.id) {
          formData.append('type', this.type.id)
        }
        if (this.date) {
          formData.append('date', this.date)
        }
        if (this.image && this.image !== '' && this.image !== undefined) {
          formData.append('file', this.image)
        }
        formData.append('fileType', 'image')
        if (this.item.uploads && this.item.uploads.length > 0) {
          formData.append('fileId', this.item.uploads[0].id)
        }
        this.$axios.post('movements', formData).then(() => {
          this.page = 1
          this.getMovements()
          this.$modal.hide('movementsModal')
        })
      },

      async saveItem() {
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        formData.append('observation', this.observation)
        formData.append('_method', 'patch')
        if (this.selectedUser && this.selectedUser.id) {
          formData.append('user_id', this.selectedUser.id)
        }
        if (this.position && this.position.id) {
          formData.append('position_id', this.position.id)
        }
        if (this.movementReason && this.movementReason.id) {
          formData.append('movement_reason_id', this.movementReason.id)
        }
        if (this.type && this.type.id) {
          formData.append('type', this.type.id)
        }
        if (this.date) {
          formData.append('date', this.date)
        }
        if (this.image && this.image !== '' && this.image !== undefined) {
          formData.append('file', this.image)
        }
        formData.append('fileType', 'image')
        if (this.item.uploads && this.item.uploads.length > 0) {
          formData.append('fileId', this.item.uploads[0].id)
        }
        this.$axios.post('movements/' + this.item.id, formData).then(() => {
          this.page = 1
          this.getMovements()
          this.$modal.hide('movementsModal')
        })
      },

      async deleteItem(item) {
        this.$axios.delete('movements/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getMovements()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getMovements()
      },
    },
  }
</script>
