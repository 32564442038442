<template>
  <div>
    <v-row align="center" class="mx-0 pa-3 text-center flex-column">
      <help :helpId="37" class="help--crud" />
      <h2 class="mx-5">Competencias o habilidades disponibles para la red</h2>
      <!-- <span class="text-body-2"> {{ company.name }}</span> -->
    </v-row>
    <v-row no-gutters justify="end" class="px-3"
      ><v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-bind="attrs"
            v-on="on"
            color="primary"
            dark
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </template>
        <span>Crear Competencia</span>
      </v-tooltip>
    </v-row>
    <v-row class="mx-0">
      <label class="col-12 py-0">Filtrar por:</label>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-text-field
          dense
          solo
          hide-details="auto"
          label="Búsqueda"
          v-model="filters.search"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <multiselect
          class="pa-0"
          track-by="id"
          label="value"
          deselect-label=""
          select-label=""
          placeholder="Tipo"
          v-model="filters.type"
          :options="typeOptions"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="false"
        />
      </v-col>
      <v-col cols="12" sm class="d-flex align-end justify-end">
        <v-btn
          small
          color="primary"
          max-width="35"
          min-width="auto"
          dark
          class="px-1 py-1"
          v-if="filterActivated"
          @click.prevent="
            filterActivated = false
            cleanFilters()
            getSkills()
          "
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              max-width="35"
              min-width="auto"
              dark
              small
              class="px-1 py-1 ml-1"
              @click.prevent="
                filterActivated = true
                getSkills()
              "
              ><v-icon dark> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <simple-list
      :title="''"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :itemKey="'id'"
      :hideFooter="true"
      :hideHeader="false"
      :multiSort="false"
      :loading="loadingSkills"
      :class="'my-2 pb-2'"
      :sortable="false"
      :page="page"
      :pages="pages"
      :showCreate="false"
      :showDelete="true"
      :buttonLink="'empresas/ficha'"
      :orderOptions="orderOptions"
      :orderBy="orderBy"
      :showGroupBy="true"
      @create:item="handleCreate()"
      @edit:item="handleEdit($event)"
      @delete:item="deleteItem($event)"
      @update:page="pagination($event)"
      @orderEvent="handleOrder($event)"
      @showPositionProfile="handleProfileRedirect($event)"
    />
    <modal name="skillsModal" :width="dialogWidth" :adaptive="true">
      <v-card class="ma-md-4" flat>
        <h2 class="text-center mb-5">
          {{ flow === 'create' ? 'Crear competencia' : 'Editar competencia' }}
        </h2>

        <v-card-text>
          <span v-if="flow === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >
          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row class="mx-0">
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  counter="100"
                  :rules="[rules.required, rules.max]"
                  label="Nombre"
                  v-model="name"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-select
                  flat
                  label="Tipo"
                  :items="typeOptions"
                  item-text="value"
                  return-object
                  v-model="selectedType"
                  :rules="[rules.required]"
                  placeholder="Selecciona un tipo de competencia"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-select
                  flat
                  label="Tipo de dato"
                  :items="fieldTypeOptions"
                  item-text="value"
                  return-object
                  v-model="selectedFieldType"
                  :rules="[rules.required]"
                  placeholder="Selecciona un tipo de dato"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="6"
                v-if="this.selectedFieldType.id !== 'boolean'"
              >
                <span class="caption"
                  >La relación entre el valor suministrado para el usuario y el valor
                  requerido por el cargo es inversa
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>
                    <span
                      >Indica si el valor que será suministrado para el usuario <br />
                      debe ser menor o igual que el valor requirido para el cargo</span
                    >
                  </v-tooltip>
                </span>
                <v-radio-group class="mt-1" v-model="invert" :rules="[rules.required]">
                  <v-radio label="Si" :value="true" />
                  <v-radio label="No" :value="false" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="handleSaveItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="handleSaveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('skillsModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>
<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  export default {
    name: 'Skills',
    components: {
      SimpleList,
    },
    props: {
      company: { type: Object },
      typeId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        loadingSkills: true,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre', value: 'name', groupable: false },
          { text: 'Tipo', value: 'typeText' },
          { text: 'Valor Cualitativo', value: 'icon', align: 'center', groupable: false },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            width: '120px',
            groupable: false,
          },
        ],
        items: [],
        item: {},
        // fieldIndex: 10,
        filterActivated: false,
        page: 1,
        pages: 1,
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          type: null,
        },
        activeOptions: [
          { id: null, value: 'Todas' },
          { id: 1, value: 'Activas' },
          { id: 0, value: 'Inactivas' },
        ],
        typeOptions: [
          { id: 0, value: 'Educación' },
          { id: 1, value: 'Experiencia' },
          { id: 2, value: 'Habilidades' },
        ],
        selectedType: { id: 2, value: 'Habilidades' },
        fieldTypeOptions: [
          { id: 'integer', value: 'Número entero' },
          { id: 'number', value: 'Número decimal' },
          { id: 'boolean', value: 'Verdadero / Falso' },
        ],
        selectedFieldType: { id: 'boolean', value: 'Verdadero / Falso' },
        active: false,
        invert: false,
        name: '',
        createdAt: '',
        toDay: new Date(),
        dateRange: '',

        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          max: (v) =>
            (v ? v.length <= 100 : true) || 'Debe poseer menos de 100 caracteres',
        },
      }
    },
    created() {
      if (this.typeId) {
        this.getSkills()
      }
    },
    watch: {},
    computed: {
      dialogWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '60%'
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      async getSkills() {
        this.loadingSkills = true
        let queryParams = ''
        let orderString = ''
        let searchString = ''
        let typeString = ''
        const withString = ''
        const appendString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.type && this.filters.type.id !== undefined) {
            typeString = '&type=' + this.filters.type.id
          }
        }
        queryParams = searchString + withString + appendString + typeString
        this.$axios
          .get('skills?&page=' + this.page + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items && this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((item) => {
              item.typeText = this.typeOptions.find((x) => x.id === item.type).value
              item.icon = item.qualitative ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'
              return item
            })
            this.loadingSkills = false
          })
      },
      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.selectedType = { id: 2, value: 'Habilidades' }
        this.selectedFieldType = { id: 'boolean', value: 'Verdadero / Falso' }
        this.invert = false

        this.$modal.show('skillsModal')
      },
      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = item.name
        this.selectedType = this.typeOptions.find((x) => x.id === item.type)
        this.selectedFieldType = this.fieldTypeOptions.find(
          (x) => x.id === item.field_type
        )
        this.invert = item.invert
        this.$modal.show('skillsModal')
      },
      async handleSaveItem() {
        const data = {}
        data.name = this.name
        data.type = this.selectedType.id
        data.field_type = this.selectedFieldType.id
        data.qualitative = this.selectedFieldType.id === 'boolean'
        data.invert = +this.invert
        if (this.flow === 'create') {
          this.$axios.post('skills', data).then(() => {
            this.page = 1
            this.getSkills()
            this.$modal.hide('skillsModal')
          })
        } else {
          this.$axios.patch('skills/' + this.item.id, data).then(() => {
            this.page = 1
            this.getSkills()
            this.$modal.hide('skillsModal')
          })
        }
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getSkills()
      },
      validate() {
        this.$refs.formCompany.validate()
      },
      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },
      cleanFilters() {
        this.filters = {
          search: '',
          type: null,
        }
      },
      async deleteItem(item) {
        this.$axios.delete('skills/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getSkills()
        })
      },
      async pagination(page) {
        this.page = page
        this.items = []
        await this.getSkills()
      },
    },
  }
</script>
